/* Terminal.css */
.terminal {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: #002200; /* Dark green background for that classic terminal look */
  padding: 20px; /* Provides padding around the entire terminal to prevent text from touching the edges */
  box-sizing: border-box;
  color: #33ff33; /* Bright green text color */
  font-family: 'Fira Code', 'Consolas', monospace; /* Monospaced font for authenticity */
  font-size: 16px; /* Adjust based on your preference */
}

.history {
  padding-left: 10px; /* Additional padding on the left for history items */
}

.input-line {
  display: flex;
  align-items: baseline;
  padding-left: 10px; /* Ensures the input line also has padding */
}

.terminal-input {
  flex-grow: 1;
  background: transparent;
  border: none;
  color: #33ff33; /* Matches the terminal text color */
  font-family: inherit; /* Inherits the terminal's monospaced font */
  font-size: inherit; /* Ensures consistent font sizing */
  outline: none; /* Removes the default focus outline */
  caret-color: #33ff33; /* Makes the caret/cursor match the text color */
}

.prompt {
  margin-right: 5px; /* Space between the prompt and the input */
}

/* Blinking Cursor Effect */
.terminal-input::after {
  content: '|';
  animation: blink-cursor 1s step-end infinite;
}

@keyframes blink-cursor {
  from, to { color: transparent }
  50% { color: inherit }
}

/* Custom Scrollbar Styling */
.terminal::-webkit-scrollbar {
  width: 8px; /* Narrow scrollbar for a sleek look */
}

.terminal::-webkit-scrollbar-thumb {
  background: #004400; /* Dark green, matching the terminal theme */
  border-radius: 4px;
}

/* Styles for different types of messages */
.line.response {
  color: #33ff33; /* Standard response color */
}

.line.command {
  color: #33ff33; /* Differentiate commands if desired */
}

.line.error {
  color: #ff3333; /* Error messages in red */
}

.line.system {
  color: #33ff33; /* System messages, customize as needed */
}

/* Markdown General Styling */
.markdown-content {
  font-family: 'Courier New', monospace;
  color: #0f0;
  line-height: 1.4;
}

/* Headers */
.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  color: #0f0;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

/* Paragraphs */
.markdown-content p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

/* Lists */
.markdown-content ul,
.markdown-content ol {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding-left: 20px;
}

/* Code Blocks and Inline Code */
.markdown-content pre,
.markdown-content code {
  background-color: #022;
  color: #0f0;
  border-radius: 4px;
  padding: 0.2em 0.4em;
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  overflow-x: auto;
}

.markdown-content pre {
  padding: 0.5em;
}

.markdown-content code {
  display: inline;
  font-size: 0.9em;
}

/* Blockquotes */
.markdown-content blockquote {
  border-left: 4px solid #0f0;
  padding-left: 1em;
  margin-left: 0;
  color: #ddd;
}

/* Tables */
.markdown-content table {
  border-collapse: collapse;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.markdown-content th,
.markdown-content td {
  border: 1px solid #0f0;
  padding: 0.5em;
}

.markdown-content th {
  background-color: #013;
}

/* Ensure mobile responsiveness */
@media (max-width: 600px) {
  .markdown-content table,
  .markdown-content pre {
    width: auto;
    overflow-x: scroll;
    display: block;
    }
    }