.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #282c34; /* Example background color */
  color: white; /* Example text color */
  font-family: 'Courier New', Courier, monospace; /* Example font */
  padding: 20px; /* Prevent content from touching the edges */
  box-sizing: border-box;
}
